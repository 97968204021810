<template>
  <div>
    <div class="row">
      <div class="col-12 col-md-6">
        <h4 class="f-bold pt-2 pl-3 cl-primary text-left">Data Promotion</h4>
      </div>
      <div class="col-12 col-md-6">
        <nav aria-label="breadcrumb">
          <ol
            class="breadcrumb float-right"
            style="background-color: transparent"
          >
            <li class="breadcrumb-item">
              <router-link to="/admin/dashboard">Dashboard</router-link>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              Data Promotion
            </li>
          </ol>
        </nav>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <button
          class="btn btn-primary float-right px-4 my-2"
          @click.prevent="promotionForm"
        >
          Add Promotion
        </button>
      </div>
    </div>
    <div class="table-responsive">
      <div class="d-flex justify-content-around" v-if="loading">
        <loader-component></loader-component>
      </div>

      <table class="table table-bordered table-hover" v-if="!loading">
        <thead class="thead-light">
          <tr>
            <th scope="col">No</th>
            <th scope="col">Promotion Code</th>
            <th scope="col">Discount</th>
            <th scope="col">Expired</th>
            <th scope="col">Status</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="data.length == 0">
            <td colspan="7">Promotion is Empty</td>
          </tr>
          <tr v-for="(promotion, p) in data" :key="p">
            <td scope="row">{{ p + 1 }}</td>
            <td>{{ promotion.code }}</td>
            <td>{{ promotion.discount }}</td>
            <td>{{ promotion.expired_at }}</td>
            <td>
              <span class="badge badge-success" v-if="promotion.status == 1"
                >Active
              </span>
              <span class="badge badge-danger" v-if="promotion.status == 0"
                >In Active
              </span>
            </td>
            <td>
              <div class="d-flex justify-content-around">
                <!-- <p
                  style="cursor: pointer"
                  class="btn btn-link mb-0"
                  @click.prevent="detailPromotion(promotion.id)"
                >
                  <span class="material-icons"> launch </span>
                </p> -->
                <p
                  style="cursor: pointer"
                  class="btn btn-link mb-0"
                  @click.prevent="deletePromotion(promotion.id)"
                >
                  <span class="material-icons"> delete </span>
                </p>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import LoaderComponent from "../../components/Loader.vue";
export default {
  name: "Promotions",
  components: { LoaderComponent },
  data() {
    return {
      loading: true,
      data: [],
    };
  },

  mounted() {
    this.getAllPromotion();
  },

  methods: {
    getAllPromotion() {
      this.loading = true;
      const endpoint = "promotion";
      this.$api.get(endpoint, (status, data, message) => {
        if (status === 200) {
          this.loading = false;
          this.data = data.promotion;
          console.log(data);
        } else {
          this.loading = false;
          console.log(message);
        }
      });
    },

    promotionForm() {
      this.$router.push("/admin/promotions/add");
    },

    deletePromotion(id) {
      Vue.swal({
        title: "Are you sure?",
        text: "This promotion will be delete",
        icon: "question",
        showCancelButton: true,
        cancelButtonColor: "#eb4d4b",
        confirmButtonText: "Yes, Delete!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.loading = true;
          const endpoint = `promotion/delete/${id}`;
          this.$api.delete(endpoint, (status, data, message) => {
            if (status === 200) {
              this.loading = false;
              Vue.swal("Success", "Promotion deleted", "success").then(() => {
                this.getAllPromotion();
              });
            } else {
              this.loading = false;
              console.log(message);
            }
          });
        }
      });
    },

    detailPromotion(id) {
      this.$router.push(`/admin/promotions/edit/${id}`);
    },
  },
};
</script>
